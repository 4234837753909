import React, { useEffect, useState } from "react"
import AccountInput from "./accountInput"
import CountryForm from "./countryInput"
import DateForm from "./dateForm"
import { useInputChange } from "../custom-hook/useForm"
import "../assets/styles/components/userInfo.scss"
import { useSelector } from "react-redux"

const UserInfo = (props: { [keys: string]: any }) => {
  const { setIsDisabled, formInputRef } = props
  const [input, handleInputChange] = useInputChange()

  const kycProfileStatus = useSelector((state: any) => state?.profileKyc.kycProfileStatus)
  const yourInfo = kycProfileStatus?.profile?.yourInfo

  useEffect(() => {
    if (yourInfo) {
      const { homeAddress, ...newObject } = yourInfo
      Object.assign(formInputRef.current, homeAddress, newObject)
    }

    const isCompleted =
      !formInputRef.current.firstName ||
      !formInputRef.current.lastName ||
      !formInputRef.current.country ||
      !formInputRef.current.street ||
      !formInputRef.current.city ||
      !formInputRef.current.state ||
      !formInputRef.current.zipCode ||
      !formInputRef.current.dateOfBirth ||
      !formInputRef.current.placeOfBirth ||
      !formInputRef.current.citizenship

    setIsDisabled(isCompleted)
    if (Object.keys(input).length != 0) Object.assign(formInputRef.current, input)
  }, [input, yourInfo])

  const handleAuthorizedManager = (event: React.ChangeEvent<HTMLInputElement>) => {
    const authorizedManager = { currentTarget: { name: "isAuthorizedManager", value: event?.target.checked } }
    handleInputChange(authorizedManager)
  }

  return (
    <div className="user-info">
      <h3 className="h3-heading">Confirm your identity</h3>
      <span className="desc-1">
        Make sure everything’s accurate so we can verify your account. <br />
        <span>
          <button className="learn-btn">Learn more</button>
        </span>
      </span>
      <div className="desc-2">
        <h6>Legal name</h6>
        <span>
          The name that appears on government IDs and official documents. If you’ve already uploaded a government ID,
          make sure the name you provide matches the name on the ID.
        </span>
      </div>

      <div className="name-form">
        <AccountInput
          type={"text"}
          name={"firstName"}
          placeholder={" "}
          value={yourInfo?.firstName}
          onChange={handleInputChange}
          label={"First Name"}
          required={true}
        />

        <AccountInput
          type={"text"}
          name={"lastName"}
          placeholder={" "}
          value={yourInfo?.lastName}
          onChange={handleInputChange}
          label={"Last Name"}
          required={true}
        />
      </div>

      <div className="section">
        <div className="desc-3">
          <h6>Home address</h6>
          <span>
            Your primary address, where you actually live (usually on utility bills). It could be different from an
            Brooi listing address.
          </span>
        </div>

        <div className="form-input">
          <p className="label">Country / Region</p>
          <CountryForm
            required={true}
            name={"country"}
            value={yourInfo?.homeAddress?.country}
            onChange={handleInputChange}
            label={"Country / Region"}
          />
        </div>

        <div className="form-input">
          <p className="label">Street</p>
          <AccountInput
            type={"text"}
            name={"street"}
            value={yourInfo?.homeAddress?.street}
            onChange={handleInputChange}
            required={true}
          />
          <span className="subscript">House name/number + street/road</span>
        </div>

        <div className="form-input">
          <p className="label">Apt, Suite. (optional)</p>
          <AccountInput
            type={"text"}
            name={"apartmentSuite"}
            value={yourInfo?.apartmentSuite}
            onChange={handleInputChange}
            required={false}
          />
          <span className="subscript">Apt., suite, building access code</span>
        </div>

        <div className="subsection-2">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">City</p>
                <AccountInput
                  name={"city"}
                  value={yourInfo?.homeAddress?.city}
                  onChange={handleInputChange}
                  type={"text"}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="subsection-2">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">State</p>
                <AccountInput
                  name={"state"}
                  value={yourInfo?.homeAddress?.state}
                  onChange={handleInputChange}
                  type={"text"}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="subsection-2">
          <div className="wrapper-1">
            <div className="wrapper-2">
              <div className="wrapper-3">
                <p className="label">ZIP Code</p>
                <AccountInput
                  name={"zipCode"}
                  value={yourInfo?.homeAddress?.zipCode}
                  onChange={handleInputChange}
                  type={"text"}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="clear"></div>
      </div>

      <div className="section">
        <h6 className="title">Date of Birth</h6>
        <DateForm name={"dateOfBirth"} value={yourInfo?.dateOfBirth} onChange={handleInputChange} required={true} />
      </div>
      <h6 className="title">Place of Birth</h6>
      <CountryForm required={true} value={yourInfo?.placeOfBirth} onChange={handleInputChange} name={"placeOfBirth"} />

      <div className="section">
        <h6 className="title">Citizenship</h6>
        <CountryForm required={true} value={yourInfo?.citizenship} onChange={handleInputChange} name={"citizenship"} />
      </div>

      <h6 className="title">Tax identification number (TIN) (optional)</h6>
      <AccountInput
        type={"text"}
        name={"tin"}
        placeholder={" "}
        value={yourInfo?.tin}
        onChange={handleInputChange}
        label={"Tax identification number (TIN) (optional)"}
        required={false}
      />

      <div className="bottom-section">
        <AccountInput
          type={"checkbox"}
          checked={yourInfo?.isAuthorizedManager}
          name={"isAuthorizedManager"}
          onChange={handleAuthorizedManager}
          extraText={"I am authorized to manage this account on behalf of the business."}
        />
      </div>
    </div>
  )
}

export default UserInfo
